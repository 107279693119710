// @next
import { useRouter } from "next/router";

// @mui
import { Box, MenuItem, Stack, IconButton, Link as MuiLink } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Iconify } from "../../atoms/Iconify";
import { usePopover } from "../../hooks/use-popover";
import { useTranslation } from "../../hooks/use-translation";
import { MenuPopover } from "../../atoms/MenuPopover/MenuPopover";

// ----------------------------------------------------------------------

type LanguagePopoverProps = {
  isOffset: boolean;
  names?: {
    az?: string;
    ru?: string;
    en?: string;
  };
  dataTestIds?: {
    button?: string;
    menuItem?: {
      az: string;
      ru: string;
      en: string;
    };
  };
};

export const LanguagePopover = ({ isOffset, names, dataTestIds }: LanguagePopoverProps) => {
  const popover = usePopover();
  const t = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  let langIcon = "";

  switch (router.locale) {
    case "ru": {
      langIcon = "twemoji:flag-russia";
      break;
    }
    case "az": {
      langIcon = "twemoji:flag-azerbaijan";
      break;
    }
    case "en": {
      langIcon = "twemoji:flag-united-kingdom";
      break;
    }
    default:
      langIcon = "tabler:world";
  }

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          display: {
            // xs: "none",
            md: "inline-flex",
          },
          ml: 1,
          width: 30,
          height: 30,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "15px",
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
          },
          zIndex: 1,
          ...(isOffset && {
            color: "text.primary",
          }),
        }}
        data-testid={dataTestIds?.button}
      >
        <Iconify icon={langIcon} />
      </IconButton>

      <MenuPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, mt: 1 }}>
        <Stack spacing={0.75}>
          <MuiLink
            href={`/az${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.az}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-azerbaijan"} />
              <Box>{names?.az ?? t("az")}</Box>
            </MenuItem>
          </MuiLink>
          <MuiLink
            href={`/ru${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.ru}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-russia"} />
              <Box>{names?.ru ?? t("ru")}</Box>
            </MenuItem>
          </MuiLink>
          <MuiLink
            href={`/en${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.en}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-united-kingdom"} />
              <Box>{names?.en ?? t("en")}</Box>
            </MenuItem>
          </MuiLink>
        </Stack>
      </MenuPopover>
    </>
  );
};
